import AnchorInfoModal from '@/compnents/anchorInfo';
import { EmployerDetailModal } from '@/pages/employer/employerManagement/employerDetail';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { MainSubStructure, NormalProgrammeComponent } from '../../../utils';
import { FinanceDetailStore } from './model';

const FinanceDetail = observer(() => {
  const store = useMemo(() => new FinanceDetailStore(), []);
  const { filterSet, mainSubStructureModel, statistics, anchorInfoModalStore, employerDetailStore } = store;

  const mainDataList = [
    {
      title: '销售额',
      value: statistics?.totalSaleAmount,
      unit: '元',
    },
    {
      title: '订单量',
      value: statistics?.totalOrderCount,
    },
  ];

  return (
    <div className="bg-[#F0F3F8] h-screen flex flex-col">
      <div className="bg-white pl-8 pr-8">
        <div className="pb-2 pt-6">
          <NormalProgrammeComponent store={filterSet} />
        </div>
      </div>

      <div className="flex justify-around pt-4 pb-4 mt-2 bg-white">
        {mainDataList.map((item) => {
          return (
            <div
              key={item.title + item.value}
              className="flex flex-col items-center"
            >
              <div className="text-primary text-20 font-600">
                {item.value}
                {item?.unit || ''}
              </div>
              <div className="mt-2">{item.title}</div>
            </div>
          );
        })}
      </div>

      <div className="flex-1 bg-white mt-2 pt-2">
        <MainSubStructure store={mainSubStructureModel} />
      </div>

      <AnchorInfoModal store={anchorInfoModalStore} />
      <EmployerDetailModal store={employerDetailStore} />
    </div>
  );
});
export default FinanceDetail;
