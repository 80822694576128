import { ASSETS } from '@/assets';
import AnchorInfoModel from '@/compnents/anchorInfo/model.ts';
import DetailModel from '@/pages/employer/employerManagement/employerDetail/model';
import type { PlatformAccountStatisticsVO } from '@/pages/finance/financeDetail/type.ts';
import { type BaseData, MainSubStructureModel, NormalProgramme, request, requireSource } from '@/utils';
import dayjs from 'dayjs';
import { action, observable } from 'mobx';
import { orderStatus } from './options';

export class FinanceDetailStore {
  constructor() {
    this.functionTypeList();
    this.mainSubStructureModel.getFilterParams = () => this.filterSet.filterItems.params;
    this.filterSet.handleSearch();
  }
  public userId = new URLSearchParams(window.location.search)?.get('id');
  @observable public statistics: PlatformAccountStatisticsVO = null; // 统计数据
  @observable anchorInfoModalStore: AnchorInfoModel = new AnchorInfoModel(); //主播信息弹框
  @observable public userOrderCount = '-';

  public employerDetailStore = new DetailModel(this);

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    pageId: `${new URLSearchParams(window.location.search)?.get('pageId')}chooseUser`,
    grid: {
      rowHeight: 120,
      columns: [
        {
          key: 'orderNumber',
          name: '订单编号',
          width: 220,
        },
        {
          key: 'packageNameList',
          name: '套餐名称',
          formatter: ({ row }) => {
            const { packageNameList } = row;
            return (
              <div className="flex items-center justify-start  h-full">
                {packageNameList?.map((e, index) => {
                  return (
                    <p
                      key={`${e.serviceName}_${index}`}
                      className="leading-[normal] ml-2"
                    >
                      {e}
                    </p>
                  );
                })}
              </div>
            );
          },
        },
        {
          key: 'projectList',
          name: '服务名称',
          formatter: ({ row }) => {
            const { projectList } = row;
            return (
              <div className="flex items-center justify-start  h-full">
                {projectList?.map((e, index) => {
                  console.log(e, 'pppp');

                  return (
                    <p
                      key={`${e.id}_${index}`}
                      className="leading-[normal] ml-2"
                    >
                      {e.projectName}
                    </p>
                  );
                })}
              </div>
            );
          },
        },
        {
          key: 'buyCount',
          name: '数量',
        },
        {
          key: 'price',
          name: '单价（元）',
        },
        {
          key: 'totalAmount',
          name: '订单金额（元）',
        },
        {
          key: 'userType',
          name: '用户类型',
          formatter: ({ row }) => {
            return Number(row.userType) === 1 ? '主播' : '雇主';
          },
        },
        {
          key: 'anchorInfo',
          name: '用户信息',
          width: 250,
          formatter: ({ row }) => {
            const { userName, userAvatar, feeTimes, buyCount, userId, userMobile } = row;
            return (
              <div
                className="flex items-center cursor-pointer h-full gap-2"
                onClick={() => {
                  if (!row?.userId) {
                    return;
                  }
                  if (row.userType === 1) {
                    //弹出主播信息资料
                    this.anchorInfoModalStore.onShow(row.userId);
                  } else {
                    this.employerDetailStore.onOpenDetailModal(row.userId);
                  }
                }}
              >
                <div className="relative">
                  <div className="rounded-[50%] w-13 h-13 m-0 p-0 overflow-hidden">
                    <img
                      src={userAvatar}
                      className="w-full h-full"
                      alt=""
                    />
                  </div>
                </div>
                <div className="text-12 leading-[18px]">
                  <div className="flex items-center">
                    {buyCount > 0 ? (
                      <>
                        <img
                          src={ASSETS.COMMON.PAY_STATUS}
                          className="w-13"
                          alt=""
                        />
                        <div className="text-[red]"> ({buyCount})</div>
                      </>
                    ) : null}
                  </div>
                  <div className="flex items-center">
                    用户昵称：{userName}
                    <img
                      src={requireSource('/common/idcard.png')}
                      alt={''}
                      className={'w-4 h-4 ml-1'}
                    />
                  </div>
                  <div>ID：{userId}</div>
                  <div>手机号：{userMobile}</div>
                </div>
              </div>
            );
          },
        },
        {
          key: 'payTime',
          name: '支付时间',
          width: 150,
          formatter: ({ row }) => {
            return row.payTime ? dayjs(row.payTime).format('YYYY-MM-DD HH:mm:ss') : '';
          },
        },
        {
          key: 'payMethod',
          name: '支付方式',
          formatter: ({ row }) => {
            const { payMethod } = row;
            return payMethod === 1 ? '微信' : '线下';
          },
        },
        {
          key: 'status',
          name: '订单状态',
          formatter: ({ row }) => {
            const { status } = row;
            return status === 1 ? '已支付' : '已退款';
          },
        },
        {
          key: 'refundTime',
          name: '退款时间',
          width: 150,
          formatter: ({ row }) => {
            return row.refundTime ? dayjs(row.refundTime).format('YYYY-MM-DD HH:mm:ss') : '';
          },
        },
        {
          key: 'channelName',
          name: '付费来源',
        },
        {
          key: 'payInvite',
          name: '付费推荐人',
          width: 400,
          formatter: ({ row }) => {
            const { inviteName, inviteUserId, inviteMobile } = row;
            return (
              <div className="flex items-center cursor-pointer h-full gap-2">
                {inviteUserId ? (
                  <div className="text-12 leading-[18px] ">
                    <div>用户昵称：{inviteName}</div>
                    <div>ID：{inviteUserId}</div>
                    <div>手机号：{inviteMobile}</div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            );
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: false,
    },
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        if (filterParams?.createTime) {
          const [startTime, endTime] = filterParams.createTime.split(',');
          filterParams.startTime = `${dayjs(startTime).format('YYYY-MM-DD')} 00:00:00`;
          filterParams.endTime = `${dayjs(endTime).format('YYYY-MM-DD')} 23:59:59`;
        }

        if (filterParams.functionTypeList) {
          filterParams.functionTypeList = filterParams.functionTypeList.split(',').map(Number);
        }

        const postParams = {
          ...filterParams,
          ...rest,
          userId: this.userId,
        };
        return request<BaseData<any>>({
          url: '/pbb/platform/sys/service/order/list',
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  });

  public filterSet: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'input',

        field: 'userKeyWord',
        label: '用户信息',
        placeholder: '请选择',
      },
      {
        type: 'input',
        field: 'servicePackageName',
        label: '套餐名称',
        placeholder: '请输入',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'functionTypeList',
        label: '服务名称',
        placeholder: '请选择',
      },
      {
        type: 'select',
        field: 'userType',
        label: '用户类型',
        placeholder: '请选择',
        data: [
          {
            label: '主播',
            value: '1',
          },
          {
            label: '雇主',
            value: '2',
          },
        ],
      },
      {
        type: 'select',
        label: '支付方式',
        field: 'payMethod',
        data: [
          {
            label: '微信',
            value: '1',
          },
          {
            label: '线下',
            value: '99',
          },
        ],
      },
      {
        type: 'select',
        label: '付费来源',
        field: 'channel',
      },
      {
        type: 'input',
        label: '付费推荐人',
        field: 'inviteKeyWord',
        placeholder: '请输入昵称/手机号/ID/公司名称',
      },
      {
        type: 'dateRange',
        field: 'createTime',
        label: '支付时间:',
      },
      {
        type: 'select',
        label: '订单状态',
        field: 'status',
        placeholder: '请选择订单状态',
        data: orderStatus,
      },
    ],

    handleSearch: () => {
      this.getStatistics();
      return this.mainSubStructureModel.onQuery();
    },
  });

  @action public onChangeModal = (key: string, visible: boolean) => {
    this[key] = visible;
  };

  @action public getStatistics = () => {
    const params = this.mainSubStructureModel.getFilterParams();
    const postParams = {
      ...params,
    };
    if (postParams?.createTime) {
      const [startTime, endTime] = (postParams.createTime as string).split(',');
      postParams.startTime = `${dayjs(startTime).format('YYYY-MM-DD')} 00:00:00`;
      postParams.endTime = `${dayjs(endTime).format('YYYY-MM-DD')} 23:59:59`;
    }

    if (postParams?.functionTypeList) {
      if (typeof postParams.functionTypeList === 'string') {
        postParams.functionTypeList = postParams.functionTypeList.split(',');
      } else if (Array.isArray(postParams.functionTypeList)) {
      }
    }

    request<BaseData<any>>({
      url: '/pbb/platform/sys/service/order/statistics',
      method: 'POST',
      data: {
        ...postParams,
        userId: this.userId,
      },
    }).then((res) => {
      this.statistics = res.data;
    });
  };

  @action public functionTypeList = async () => {
    const dictMaps = await request<BaseData<any>>({
      url: '/pbb/platform/anon/system/sysDict/getDictMap',
      method: 'post',
      data: {
        dictTypeList: ['register_channel', 'service_name', 'employer_service_name'],
      },
    });

    const registerList = dictMaps.data?.register_channel?.map((e) => {
      return { label: e.dictName, value: e.dictValue };
    });

    this.filterSet.filterItems.addDict({ channel: registerList });

    //服务名称
    const functionTypeList = [
      ...(dictMaps.data?.service_name || []),
      ...(dictMaps?.data?.employer_service_name || []),
    ]?.map((e: any) => {
      return { label: e.dictName, value: e.dictValue };
    });
    this.filterSet.filterItems.addDict({ functionTypeList });
  };
}
